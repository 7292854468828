<div class="tournament-card">
  <div class="img-container">
    <img [src]="tournament.image" alt="" />
  </div>
  <div class="meta">
    <h4 class="name">
      {{ tournament.name }}
    </h4>
  </div>
</div>
