import { Environment } from '../app/data/models/Environment';

export const environment: Environment = {
  APP_ENV: 'DEVELOPMENT',
  PRODUCTION: true,
  ALLOW_LOGGING: true,
  ALLOW_VCONSOLE: false,

  LOCAL_STORAGE_PREFIX: 'gamix_',

  FIREBASE: {
    apiKey: 'AIzaSyCgaOE1Fvmd7_0txXtCUhxq-Rf_XbRZV0A',
    authDomain: 'gamix-fb.firebaseapp.com',
    projectId: 'gamix-fb',
    appId: '1:392737586688:web:f3443837667ba2ad6922e5',
    messagingSenderId: '392737586688',
    vapidKey: '',
  },
  API_CONSTANTS: {
    TOURNAMENT_LIST: {
      URL: 'https://us-central1-gamix-fb.cloudfunctions.net/getTournamentList'
    },
    HOME_LIST: {
      URL: 'https://us-central1-gamix-fb.cloudfunctions.net/getHomePageGames'
    }
  }
};
