import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NoAuthGuard } from './core/guards/no-auth.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { ContactComponent } from './layouts/contact/contact.component';
import { TermsComponent } from './layouts/terms/terms.component';
import { PrivacyComponent } from './layouts/privacy/privacy.component';

const routes: Routes = [
  {
    path: 'games/:tag',
    loadChildren: () =>
      import('@app/modules/game-list/game-list.module').then((mod) => mod.GameListModule),
  },

  {
    path: 'play/:id',
    loadChildren: () =>
      import('@modules/game-play/game-play.module').then((mod) => mod.GamePlayModule),
    // canActivate: [AuthGuard],
  },

  {
    path: '404',
    loadChildren: () => import('@modules/not-found/not-found.module').then((mod) => mod.NotFoundModule),
  },

  // {
  //   path: 'auth',
  //   loadChildren: () => import('@modules/auth/auth.module').then((mod) => mod.AuthModule),
  //   canActivate: [NoAuthGuard],
  // },

  // {
  //   path: 'privacypolicy',
  //   component: PrivacyComponent,
  // },

  // {
  //   path: 'terms',
  //   component: TermsComponent
  // },

  // {
  //   path: 'contact',
  //   component: ContactComponent
  // },
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('@modules/home/home.module').then((mod) => mod.HomeModule),
  },

  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '404',
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true,enableTracing: false, scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
