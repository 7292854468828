import { Component, Input } from '@angular/core';
import { Tournament } from '@app/data/models/games/Tournament';

@Component({
  selector: 'app-tournament-card-secondary',
  templateUrl: './tournament-card-secondary.component.html',
  styleUrls: ['./tournament-card-secondary.component.scss']
})
export class TournamentCardSecondaryComponent {
  @Input() tournament: Tournament;
}
