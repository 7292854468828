import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private showLoadingSubject = new BehaviorSubject(true);
  readonly showLoading$ = this.showLoadingSubject.asObservable().pipe(delay(0));

  showLoading() {
    console.log(`LoadingService.showLoading`);

    this.showLoadingSubject.next(true);
  }

  hideLoading() {
    console.log(`LoadingService.hideLoading`);

    this.showLoadingSubject.next(false);
  }
}
