import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  private onToggleSidebarSubject = new Subject<void>();
  private onOpenSidebarSubject = new Subject<void>();
  private onCloseSidebarSubject = new Subject<void>();

  readonly onToggleSidebar$ = this.onToggleSidebarSubject.asObservable();
  readonly onOpenSidebar$ = this.onOpenSidebarSubject.asObservable();
  readonly onCloseSidebar$ = this.onCloseSidebarSubject.asObservable();

  toggleSidebar() {
    console.log(`SidebarService.toggleSidebar`);
    this.onToggleSidebarSubject.next();
  }

  openSidebar() {
    console.log(`SidebarService.openSidebar`);
    this.onOpenSidebarSubject.next();
  }

  closeSidebar() {
    console.log(`SidebarService.closeSidebar`);
    this.onCloseSidebarSubject.next();
  }
}
