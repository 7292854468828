import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

const imports = [MatSidenavModule, MatIconModule, MatDividerModule, MatButtonModule, MatCardModule];

@NgModule({
  declarations: [],
  imports,
  exports: [...imports]
})
export class MaterialModule {}
