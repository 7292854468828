import { Component, OnInit, HostListener } from '@angular/core';
import { SidebarService } from '@app/core/services/sidebar.service';
import { UserService } from '@app/core/services/user.service';
import { AuthService } from '@app/core/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  opened = false;
  sidebarHeight = window.innerHeight;
  defaultImageUrl = '/assets/images/default_profile.png';

  constructor(
    private sidebarService: SidebarService,
    public userService: UserService,
    public authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.sidebarService.onOpenSidebar$.subscribe(() => this.open());
    this.sidebarService.onCloseSidebar$.subscribe(() => this.close());
    this.sidebarService.onToggleSidebar$.subscribe(() => this.toggle());

    this.router.events.subscribe(() => this.close());
  }

  open() {
    this.opened = true;
  }

  close() {
    this.opened = false;
  }

  toggle() {
    this.opened = !this.opened;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.sidebarHeight = window.innerHeight;
  }

  getDrawerStyle() {
    return this.opened ? { height: this.sidebarHeight + 'px' } : {};
  }
}
