import { Component, OnInit } from '@angular/core';
import { LoadingService } from '@app/core/services/loading.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  constructor(private loadingService: LoadingService) {}

  ngOnInit(): void {
    this.loadingService.hideLoading();
  }
}
