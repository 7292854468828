import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstWord'
})
export class FirstWordPipe implements PipeTransform {
  transform(value: string): unknown {
    if (!value) {
      return '';
    }
    return value.split(' ')[0];
  }
}
