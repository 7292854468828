import { Pipe, PipeTransform } from '@angular/core';
import { TagPriority } from '@app/data/TagPriority';

@Pipe({
  name: 'sortTagByPriority',
})
export class SortTagByPriorityPipe implements PipeTransform {
  transform(value: { key: string; value: any }[]): any {
    return value.sort((a, b) => {
      if (TagPriority[b.key] === undefined || TagPriority[a.key] < TagPriority[b.key]) {
        return -1;
      }
      return 1;
    });
  }
}
