import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class AppUpdateService {

  constructor(
    public readonly updates: SwUpdate,
    ) {
    // this.updates.available.subscribe(event => {
    //   this.showAppUpdateAlert();
    // });
  }
  public checkForUpdates(): void {
    console.log('checking for updates.....')
    this.updates.available.subscribe(event => this.promptUser());
  }
  private promptUser(): void {
    console.log('updating to new version');
    this.updates.activateUpdate().then(() => document.location.reload()); 
  }

  
  // showAppUpdateAlert() {
  //   const header = 'App Update available';
  //   const message = 'Choose Ok to update';
  //   const action = this.doAppUpdate;
  //   const caller = this;
  //   // Use MatDialog or ionicframework's AlertController or similar
  // }

  doAppUpdate() {
    this.updates.activateUpdate().then(() => document.location.reload());
  }

  
}
