<div class="contact">
  <div class="default-header-container">
    <app-header></app-header>
  </div>

  <div class="content">
    <mat-card class="card">
      <mat-card-header class="header">
        <mat-card-title class="title">Contact Us</mat-card-title>
      </mat-card-header>

      <mat-card-content class="body">
        <div
          class="LI-profile-badge"
          data-version="v1"
          data-size="large"
          data-locale="en_US"
          data-type="vertical"
          data-theme="light"
          data-vanity="sagardabas"
        >
          <a class="LI-simple-link" href="https://in.linkedin.com/in/sagardabas?trk=profile-badge"
            >Sagar Dabas</a
          >
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <app-footer class="default-footer"> </app-footer>
</div>
