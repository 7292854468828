import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { SidebarService } from '@app/core/services/sidebar.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  scrolledDown = false;

  constructor(private sidebarService: SidebarService) {}

  ngOnInit(): void {
    this.scrolledDown = this.hasScrolledDown();
  }

  menuButtonClicked() {
    this.sidebarService.toggleSidebar();
  }

  @HostListener('window:scroll')
  windowScroll() {
    this.scrolledDown = this.hasScrolledDown();
  }

  private hasScrolledDown(): boolean {
    const scrollY = window.scrollY;
    const offset = 10;

    return scrollY > offset;
  }

  onScorllDownClasses(): object {
    return {
      'scrolled-down': this.scrolledDown,
      'mat-elevation-z8': this.scrolledDown
    };
  }
}
