<div class="app">
  <app-header></app-header>
  <app-loading *ngIf="loadingService.showLoading$ | async"></app-loading>
  <!-- p{{ show | async | json }} -->
  <app-sidebar>
    <div class="content" [style.min-height]="minHeight">
      <router-outlet></router-outlet>
    </div>
  </app-sidebar>
</div>
