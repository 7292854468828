<div class="container-fluid">

<p>Free online games at your fingertips!! Enjoy unlimited browser games through Gamix.in Powered by <a href="https://www.gamixlabs.com/" target="_blank">Gamix Labs</a>.</p>

<p>Gamix Labs offers you to play 50+ exciting online browser games, like Ludo Champ, Tetris, Flappy Bird, Hexagon Puzzle, Fruit Samurai, Knife Hit, Rowdy Jax and so on with multiplayer and other amazing features. </p>

<p>Gamix Labs is a full-cycle game development company, offers art, animation, and development services. We also offer gamification services to collect visitors' data, insights, and interests in extraordinary ways that help businesses to make bespoke decisions and get better ranking on search engines.</p>  

<p>If you are looking for dedicated game development team, write us :- <a href="mailto:support@gamixlabs.com">support@gamixlabs.com</a></p>


</div>


<footer>
  <div class="content">
    <a href="https://www.facebook.com/2sgamix/" class="img-container">
      <img src="/assets/images/icons/footer/facebook.png" alt="facebook" />
    </a>
    <a href="https://www.linkedin.com/company/2sgamix" class="img-container">
      <img src="/assets/images/icons/footer/linkedin.png" alt="linkedin" />
    </a>
    <a href="mailto:support@2sgamix.com" class="img-container">
      <img src="/assets/images/icons/footer/mail.png" alt="email" />
    </a>
  </div>
</footer>
