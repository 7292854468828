import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { User as FirebaseUser } from 'firebase/firebase-auth';
import { User } from '@app/data/models/User';
import { BehaviorSubject } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private firebaseUser: FirebaseUser | null;
  private userSubject = new BehaviorSubject<User | null>(null);

  readonly user$ = this.userSubject.asObservable();

  constructor(private auth: AuthService) {
    this.registerEvents();
  }

  private registerEvents() {
    this.auth.onUserLogin$.subscribe((user) => {
      this.onLogIn(user);
    });

    this.auth.onUserLogout$.subscribe(() => {
      this.onLogOut();
    });
  }

  private onLogIn(user: FirebaseUser): void {
    this.firebaseUser = user;

    this.onUserChange();
    this.setLocalStorage();
  }

  private onLogOut(): void {
    this.firebaseUser = null;
    this.onUserChange();
  }

  private onUserChange(): void {
    console.log(`User changed. isNull: ${!this.firebaseUser}`);

    const userDetails = this.getUserDetails();
    this.userSubject.next(userDetails);
  }

  private getUserDetails(): User | null {
    if (!this.firebaseUser) {
      return null;
    }

    const { uid, phoneNumber, photoURL, displayName } = this.firebaseUser;
    return {
      userid: uid,
      phone: phoneNumber,
      image: photoURL,
      name: displayName,
    };
  }

  private setLocalStorage() {
    const userDetails = this.firebaseUser.providerData[0];
    if (userDetails) {
      const key = environment.LOCAL_STORAGE_PREFIX + 'user_details';
      localStorage.setItem(key, JSON.stringify(userDetails));
    }

    const tokens = (this.firebaseUser.toJSON() as any).stsTokenManager;
    if (tokens) {
      const key = environment.LOCAL_STORAGE_PREFIX + 'user_tokens';
      localStorage.setItem(key, JSON.stringify(tokens));
    }
  }
}
