import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth/';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import  * as auth from 'firebase/auth';
import { User } from 'firebase/auth';
// import {auth} from 'firebase/firebase-auth'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private otpConfirmationResult: any | null = null;
  private onUserLoginSubject = new Subject<User>();
  private onUserLogoutSubject = new Subject<void>();
  private isAuthenticatedSubject = new BehaviorSubject(false);
  private credentialsFB: any = null;

  readonly onUserLogin$ = this.onUserLoginSubject.asObservable();
  readonly onUserLogout$ = this.onUserLogoutSubject.asObservable();
  readonly isAuthenticated$ = this.isAuthenticatedSubject.asObservable();

  redirectAfterLoginUrl: string | null;

  constructor(private afAuth: AngularFireAuth, private router: Router) {
    this.afAuth.onAuthStateChanged((user) => {
      console.log('Auth state changed: ', user);

      if (user) {
        this.userLoggedIn(user);
      }
    });
  }

  loginWithPhone(otp: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        console.log(`Trying to login with otp: ${otp}`);

        if (!this.otpConfirmationResult) {
          console.error(`OTP CONFIRMATION OBJECT IS NULL`);
          reject(new Error('INVALID CONFIRMATION RESULT OBJECT'));
          return;
        }

        await this.otpConfirmationResult.confirm(otp);
        this.otpConfirmationResult = null;

        this.userLoggedIn(this.afAuth.currentUser);

        resolve();
        return;
      } catch (error) {
        reject(new Error('INVALID OTP'));
      }
    });
  }

  sendOTPToLogin(phone: string, reCaptchaContainerId: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        console.log(`Trying to send otp to ${phone}`);

        // auth().settings.appVerificationDisabledForTesting = true;
        // console.warn(`reCaptcha is disabled for testing. Consider enabling in production.`);

        // const recaptchaVerifier = this.getVerifier(reCaptchaContainerId);
        // this.otpConfirmationResult = await this.afAuth.signInWithPhoneNumber(
        //   phone,
        //   recaptchaVerifier
        // );
        resolve();
      } catch (e) {
        console.error(`ERROR OCCURED WHILE SENDING OTP TO LOGIN: ${JSON.stringify(e)}`);
        reject(e);
      }
    });
  }

  logout(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.afAuth.signOut();
        this.userLoggedOut();
        this.router.navigateByUrl('/').catch();
        resolve();
      } catch (err) {
        reject(err);
      }
    });
  }

  loginWithFacebook(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        console.log('AuthService.loginWithFacebook');

        const provider = new auth.FacebookAuthProvider();
        provider.addScope('user_friends');

        this.credentialsFB = await this.afAuth.signInWithPopup(provider);
        console.log('Got fb credentials', this.credentialsFB);

        this.userLoggedIn(this.afAuth.currentUser);
        this.setLocalStorage();

        resolve();
      } catch (err) {
        console.error(`ERROR OCCURED IN AuthService.loginWithFacebook: `, err);
        reject(err);
      }
    });
  }

  getFacebookCredential(): auth.UserCredential | null {
    return this.credentialsFB;
  }

  private userLoggedIn(user: any) {
    console.log(`User logged in: `, user.toJSON());
    this.onUserLoginSubject.next(user);
    this.isAuthenticatedSubject.next(true);
  }

  private userLoggedOut() {
    console.log(`User logged out`);
    this.onUserLogoutSubject.next();
    this.isAuthenticatedSubject.next(false);
  }

  // private getVerifier(containerId: string): auth.RecaptchaVerifier {
  //   return new auth.RecaptchaVerifier(containerId, {
  //     size: 'invisible',
  //   });
  // }

  private setLocalStorage() {
    const token: string = (this.credentialsFB?.credential?.toJSON() as any)?.oauthAccessToken;
    const userInfo = this.credentialsFB?.additionalUserInfo;

    if (token || userInfo) {
      const key = environment.LOCAL_STORAGE_PREFIX + 'facebook_user';

      const user = {
        accessToken: token,
        ...userInfo,
      };

      localStorage.setItem(key, JSON.stringify(user));
    }
  }
}
