import { Component, OnInit } from '@angular/core';
import { UserService } from '@app/core/services/user.service';

@Component({
  selector: 'app-profile-preview',
  templateUrl: './profile-preview.component.html',
  styleUrls: ['./profile-preview.component.scss']
})
export class ProfilePreviewComponent implements OnInit {
  defaultProfileUrl = '/assets/images/default_profile.png';

  constructor(public userService: UserService) {}

  ngOnInit(): void {}
}
