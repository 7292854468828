export enum Tags {
  ALL = 'all',
  FEATURED = 'featured',
  BOARD = 'board',
  PUZZLE = 'puzzle',
  ACTION = 'action',
  CASUAL = 'casual',
  CASINO = 'casino',
  MULTIPLAYER = 'multiplayer',
  NEW = 'new',
}
