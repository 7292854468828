<div class="privacy">
  <div class="default-header-container">
    <app-header></app-header>
  </div>

  <div class="content">
    <mat-card class="card">
      <mat-card-header class="header">
        <mat-card-title class="title">Privacy Policy</mat-card-title>
      </mat-card-header>
      Legal Terms & Privacy Policy COPYRIGHT (c) 2018-20 Gamix.

      <p>
        No portion of the Gamix sites, which includes this site, may be copied or redistributed in
        any manner without the express written consent of Gamix. All Gamix games, and all other
        Gamix software title names and their respective logos are copyright of Gamix.
      </p>

      <h3>
        TERMS OF SERVICE
      </h3>

      <h4>
        GOVERNING AGREEMENT
      </h4>
      <p>
        The terms of this agreement (“Terms of Service”) govern the relationship between you (“The
        User”) and Gamix. (“Gamix” or “We”) regarding your use of Gamix’s software titles, social
        games and related services and Gamix websites (the “Service”). Gamix reserves the right to
        edit these Terms of Service and policies at any time.
      </p>

      <h4>
        PRIVACY POLICY
      </h4>

      <p>
        The Privacy Policy describes how we treat your personal information when you use Gamix
        sites, Android/IOS apps and other applications including social games and services from
        Gamix. At Gamix, we respect your right to privacy. We will never sell or otherwise pass on
        your name, e-mail or other information to a third party without your consent except for when
        it is required to provide you the service you are using.What information do we collect? When
        you use software or services from Gamix, we collect some personal information to allow us to
        provide you the service, fulfill your requirement and provide you the customer support. This
        information may include your account information for Google, Apple, Facebook and other
        networks, your name, address, phone number, your device information, registration data,
        email address, and any other information that you entered while using our software and
        services. We do not store the credit card information.How is this information used? Only
        Gamix has access to any such information. We will not give or sell your private information
        to any other company for any purpose without your written consent. We may use this
        information to notify you of important announcements regarding our software developments,
        software upgrades, special offers and to provide you the application/software support. In
        Gamix social games, we do not delete the past game results or records. Even if you delete
        the game application from your phone or do not join the game again, the past game results
        are not deleted.
      </p>

      <h4>
        Security
      </h4>

      <p>
        Gamix will take reasonable steps to protect your personal information from loss, misuse,
        unauthorized access, disclosure or unauthorized alteration. By using any Gamix software or
        creating an account or accessing or using the Service you accept and agree to be bound by
        these Terms of Service.
      </p>

      <h4>
        GRANT OF A LICENSE TO USE THE SERVICE
      </h4>
      <p>
        Subject to your compliance with Terms of Service, Gamix grants you a revocable,
        non-exclusive limited license to access and use the Services. This license is
        non-transferable. You agree to use the Service for your own non-commercial purposes. You
        play some games using (or to win) paytm cash or other virtual currency. By accepting to use
        Gamix’s game application, you fully understand and agree that you can lose money because of
        many reasons besides losing while playing the game. Some of the reasons are client’s data
        connection, Internet connection, improper behaviour of the client (game app), Gamix server
        problem, sudden increase in traffic, someone else using your account or your account is
        hacked and many more.Gamix, its subsidiaries and associates are not liable for any loss of
        money due to functioning as well as malfunctioning of its servers and software including the
        games. When you play any of the games by Gamix, you accept and agree to play it only for fun
        and entertainment and Gamix is not liable for any damage or loss or whatsoever.
      </p>

      <h4>
        USE OF SERVICE – RESTRICTIONS
      </h4>
      <p>
        The following restrictions apply to use of the Service:
        <br />
      </p>

      <ul class="list">
        <li>
          a) You must not use the Service if you are under the age of 13. You must deny anyone under
          13 to use your account. You are fully responsible for any unauthorized use of the Service
          including not limited to the use of credit card or any payment by any method.
        </li>
        <li>
          b) You shall use your account only for non-commercial entertainment purposes. You shall
          not use the Service for any other purpose.
        </li>
        <li>
          c) You shall not use your account for any illegal activity.
        </li>
        <li>
          d) You shall not use your account to transmit repetitive messages (spam), junk e-mail,
          advertise and solicit.
        </li>
        <li>
          e) You shall not post any objectionable and offensive information including but not
          limited to abusive, threatening, racial, sexual, or obscene.
        </li>
        <li>
          f) You will not use your account to cheat or hack the game by any means.
        </li>
        <li>
          e) You shall not sublicense, lease, trade, gift, sell or otherwise transfer your account
          or associated virtual items partly or fully to anyone without written permission from
          Gamix.
        </li>
        <li>
          f) You shall not buy or purchase virtual items or virtual money from any unauthorized
          source.
        </li>
        <li>
          g) You shall not create or use more than one Facebook account to use the Service.
        </li>
        <li>
          h) You shall not use any of the Services or create an account or use an account if you
          have been removed or banned from using the Service.
        </li>
        <li>
          i) You shall not use any of the Gamix Services if you do not agree with the Terms of
          Service, Privacy Policy or any other policy, and your license to use the services shall
          immediately terminate.
        </li>
      </ul>
    </mat-card>
  </div>

  <app-footer class="default-footer"></app-footer>
</div>
