import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { SafePipe } from './pipes/safe.pipe';
import { TournamentCardSecondaryComponent } from './tournament-card-secondary/tournament-card-secondary.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MaterialModule } from '@app/core/material/material.module';
import { LayoutModule } from '@angular/cdk/layout';
import { RouterModule } from '@angular/router';
import { ProfilePreviewComponent } from './profile-preview/profile-preview.component';
import { FooterComponent } from './footer/footer.component';
import { LoadingComponent } from './loading/loading.component';
import { FirstWordPipe } from './pipes/first-word.pipe';
import { SortTagByPriorityPipe } from './pipes/sort-tag-by-priority.pipe';

const declarations = [
  HeaderComponent,
  SafePipe,
  FirstWordPipe,
  TournamentCardSecondaryComponent,
  SidebarComponent,
  ProfilePreviewComponent,
  FooterComponent,
  LoadingComponent,
  SortTagByPriorityPipe,
];

@NgModule({
  declarations: [...declarations],
  imports: [CommonModule, MaterialModule, LayoutModule, RouterModule],
  exports: [CommonModule, ...declarations],
})
export class SharedModule {}
