<mat-drawer-container class="sidebar-container" [hasBackdrop]="true" [ngStyle]="getDrawerStyle()">
  <mat-drawer mode="over" [(opened)]="opened" class="sidebar">
    <div class="container">
      <div class="header">
        <button class="back-button" (click)="close()"></button>
        <div routerLink="auth" class="profile">
          <div class="profile-picture-container">
            <img [src]="(userService.user$ | async)?.image || defaultImageUrl" alt="" />
          </div>
          <span class="name">{{ (userService.user$ | async)?.name || 'Login' }}</span>
        </div>
      </div>

      <div class="body">
        <ul class="primary-list list">
          <li class="primary-list-item list-item home" routerLink="/">
            <button>
              Home
            </button>
          </li>
          <mat-divider></mat-divider>
        </ul>
        <ul class="secondary-list list">
          <!-- <li class="secondary-list-item list-item contact" routerLink="contact">
            <button>
              Contact Us
            </button>
          </li> -->
          <li class="secondary-list-item list-item privacy" routerLink="privacypolicy">
            <button>
              Privacy Policy
            </button>
          </li>
          <!-- <li class="secondary-list-item list-item terms" routerLink="terms">
            <button>
              Terms & Conditions
            </button>
          </li> -->
        </ul>
      </div>

      <mat-divider class="divider"></mat-divider>

      <!-- <div *ngIf="authService.isAuthenticated$ | async" class="footer">
        <ul class="list">
          <li (click)="authService.logout()" class="list-item logout">
            <button>
              Sign out
            </button>
          </li>
        </ul>
      </div> -->
    </div>
  </mat-drawer>

  <mat-drawer-content class="sidebar-content">
    <ng-content></ng-content>
  </mat-drawer-content>
</mat-drawer-container>
