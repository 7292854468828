import { Tags } from './Tags';

// Lower number === Higher priority
export const TagPriority = {
  [Tags.FEATURED]: 10,
  [Tags.BOARD]: 30,
  [Tags.ACTION]: 20,
  [Tags.PUZZLE]: 40,
  [Tags.CASUAL]: 50,
  [Tags.CASINO]: 60,
  [Tags.ALL]: 1,
  [Tags.MULTIPLAYER]: 0.1,
  [Tags.NEW]: 0,
};
