import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import VConsole from 'vconsole';

if (environment.APP_ENV === 'PRODUCTION') {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));

if (environment.ALLOW_VCONSOLE) {
  const vConsole = new VConsole();
}

if (!environment.ALLOW_LOGGING && window) {
  const emptyFunc = () => {};

  window.console.log = emptyFunc;
  window.console.error = emptyFunc;
  window.console.info = emptyFunc;
  window.console.count = emptyFunc;
  window.console.debug = emptyFunc;
  window.console.table = emptyFunc;
  window.console.warn = emptyFunc;
}
