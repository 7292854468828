import { Component, OnInit, HostListener } from '@angular/core';
import { UserService } from './core/services/user.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';
import { LoadingService } from './core/services/loading.service';
import { AppUpdateService } from './services/app-update.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  minHeight = '100vh';
  message:any = null;

  // TODO: UserService is only used here to call userService's constructor. Fix it
  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    public loadingService: LoadingService,
    private appUpdate: AppUpdateService
  ) {
    this.appUpdate.checkForUpdates()
  }

  ngOnInit(): void {
    this.onResize();
    this.saveFCMToken();
    console.log('Screen: ', screen);
  }

  saveFCMToken() {
    this.route.queryParamMap.subscribe((params) => {
      console.log(`Got query params: `, params.keys);

      const fcmToken = params.get(`fcm`);
      if (fcmToken) {
        console.log(`fcm: ${fcmToken}`);

        const key = environment.LOCAL_STORAGE_PREFIX + 'fcm';
        localStorage.setItem(key, fcmToken);
      }
    });
  }

  @HostListener('window:resize')
  onResize() {
    this.minHeight = window.innerHeight + 'px';
  }
}
